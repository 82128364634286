import { useNavigate } from "react-router-dom";
import { infoCardDescriptions, infoCardTitles } from "../../constants/step";

import "./InfoCard.css";

type Props = {
  register?: boolean;
  text: string | null;
  pathToContact: string[];
  onCloseCallback: () => void;
  goToAnimation?: () => void;
};

const InfoCard = ({
  onCloseCallback,
  pathToContact,
  text,
  register = false,
  goToAnimation,
}: Props) => {
  const navigate = useNavigate();

  const hash = window.location.hash;
  const token = hash.startsWith("#") ? hash.substring(1) : undefined;

  const registerUrl = `${process.env.REACT_APP_EXTERNAL_LINK}/register`;

  const handleButtonClick = (): void => {
    if (goToAnimation) {
      goToAnimation();
      return;
    }
    if (!register && !goToAnimation) {
      const route = token ? `/contact/#${token}` : "/contact";
      navigate(route, { state: pathToContact.concat(text!) });
      return;
    }

    if (token) {
      window.location.href = `${registerUrl}?code=${token}`;
      return;
    }

    window.location.href = registerUrl;
  };

  return (
    <>
      <div
        className="info-card-close-btn"
        onClick={onCloseCallback}
        style={{ zIndex: 10000, position: "relative" }}
      >
        <img src="./close-icon.png" alt="" />
      </div>
      <div className="info-card-main-cont">
        <div className="info-card-cont">
          <p className="info-card-title">
            {infoCardTitles[text!] ||
              "Please contact us for any further questions."}
          </p>
          <p className="info-card-text">{infoCardDescriptions[text!] || ""}</p>
        </div>
        <div className="info-card-btn no-select" onClick={handleButtonClick}>
          {register ? "register" : goToAnimation ? "connect" : "contact"}
        </div>
      </div>
    </>
  );
};

export default InfoCard;
