import { useMediaQuery, useTheme } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Steps } from "../../../constants/step";
import { NavButton } from "../../buttons/nav/NavButton";
import InfoCard from "../../infoCard/InfoCard";

import LogoLayer from "../../layers/logo/LogoLayer";
import "./Step.css";

const blockedPaths = ["Food", "Clothing"];

type StepProps = {
  step: Steps;
  buttons: string[];
  unlockedButton: string | undefined;
  pathToContact: string[];
  setPathToContact: Dispatch<SetStateAction<string[]>>;
  nextStepCallback: () => void;
  setValue: Dispatch<SetStateAction<string>>;
  goToAnimation?: () => void;
  hiddenLogo?: boolean;
  backToLandingCallback?: () => void;
};

const Step = ({
  step,
  buttons,
  unlockedButton,
  nextStepCallback,
  pathToContact,
  setPathToContact,
  setValue,
  goToAnimation,
  hiddenLogo = false,
  backToLandingCallback,
}: StepProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const animationDuration = 500;

  const [showHoverCard, setShowHoverCard] = useState<boolean>(false);
  const [highlightedBtn, setHighlightedBtn] = useState<null | string>(null);
  const [animationClass, setAnimationClass] = useState<string>("steps-fade-in");
  const [register, setRegister] = useState<boolean>(false);

  useEffect(() => {
    if (animationClass === "steps-fade-out") {
      const timeout = setTimeout(() => {
        nextStepCallback();
        setAnimationClass("steps-fade-in");
      }, animationDuration);
      return () => clearTimeout(timeout);
    }
  }, [animationClass, nextStepCallback]);

  const handleNextStep = (text: string): void => {
    if (step === Steps.Industry && blockedPaths.includes(text)) return;
    setValue(text);
    if (
      unlockedButton === text &&
      buttons.includes(text) &&
      step !== Steps.IndustrySubType
    ) {
      setAnimationClass("steps-fade-out");
      setShowHoverCard(false);
      setHighlightedBtn(null);
      setPathToContact((prev) => [...prev, text]);
      return;
    }

    setRegister(step === Steps.IndustrySubType && text === unlockedButton);
    setShowHoverCard(true);
    setHighlightedBtn(text);
  };

  const onHoverCardClose = () => {
    setShowHoverCard(false);
    setHighlightedBtn(null);
  };

  return (
    <>
      <LogoLayer
        hidden={hiddenLogo}
        backToLandingCallback={backToLandingCallback}
      />
      {((!showHoverCard && isMobile) || !isMobile) && (
        <div className="steps-cont">
          <div
            className={`steps-content ${animationClass}`}
            style={{
              marginBottom:
                highlightedBtn && window.innerHeight < 750 ? "18rem" : "10rem",
            }}
          >
            {buttons.map((text, index) => (
              <NavButton
                key={`${text}-${index}`}
                text={text}
                highlighted={highlightedBtn === text}
                unlocked={unlockedButton === text && buttons.includes(text)}
                onClickCallback={() => handleNextStep(text)}
                disabled={
                  step === Steps.Industry && blockedPaths.includes(text)
                }
              />
            ))}
          </div>
        </div>
      )}

      {showHoverCard && (
        <InfoCard
          key={highlightedBtn}
          pathToContact={pathToContact}
          text={highlightedBtn}
          register={register}
          onCloseCallback={onHoverCardClose}
          goToAnimation={goToAnimation}
        />
      )}
    </>
  );
};

export default Step;
