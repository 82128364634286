import "./NavButton.css";

type NavButtonProps = {
  text: string;
  highlighted: boolean;
  unlocked: boolean;
  disabled: boolean;
  onClickCallback: () => void;
};

export const NavButton = ({
  text,
  highlighted,
  unlocked,
  disabled,
  onClickCallback,
}: NavButtonProps) => {
  return (
    <div
      className={`nav-button ${highlighted && !disabled ? "clicked" : ""} ${
        unlocked ? "unlocked" : ""
      } no-select`}
      style={{
        borderColor: disabled ? "grey" : unlocked ? "#1E6F30" : "#FFAD00",
      }}
      onClick={onClickCallback}
    >
      {text === "Expert" ? "Experts" : text}
    </div>
  );
};
